import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as Chart from "chart.js";


@Component({
  selector: 'app-stock-analysis',
  templateUrl: './stock-analysis.component.html',
  styleUrls: ['./stock-analysis.component.css']
})
export class StockAnalysisComponent implements OnInit {

  constructor(public httpClient: HttpClient) { }

  public stockSymbols: Array<string>;

  stockPrices = [
  ];

  dates = [
  ];

  lineChartData: ChartDataSets[] = [
    { data: this.stockPrices, label: 'Price' },
  ];
  lineChartLabels: Label[] = this.dates;
  lineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: 25
        }
      }]
    }
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      // backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];
  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';

  ngOnInit(): void {
    this.getStockSymbols();
    // harding this value at first just cause
    this.getStockHistory('AAPL');
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;


  getStockHistory(symbol:string) {
    let params = new HttpParams();
    params = params.append('symbol', symbol);
    this.httpClient.get<Map<string, string[]>>(environment.serverUrl + '/query/getStockHistory', {params} ).subscribe(result => {
      this.dates = result['dates'];
      this.stockPrices = result['stockPrices'];
      this.chart.chart.data.datasets[0].data = this.stockPrices;
      this.chart.chart.data.labels = this.dates;
      this.chart.chart.update();
    });
  }

  getStockSymbols() {
    this.httpClient.get<string[]>(environment.serverUrl + '/query/getStockSymbols').subscribe(result => {
      this.stockSymbols = result;
    });
  }

  onOptionsSelected(symbol:string){
    this.getStockHistory(symbol)
  }

}
