import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {FindOptionsComponent} from './findOptions/findOptions.component';
import {AboutComponent} from './about/about.component';
// import {BlogComponent} from './blog/blog.component';
import {StockAnalysisComponent} from './stock-analysis/stock-analysis.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'optionScanner', component: FindOptionsComponent },
  { path: 'stockAnalysis', component: StockAnalysisComponent },
  { path: 'about', component: AboutComponent },
  // { path: 'blog', component: BlogComponent },
  // { path: 'blog/:id/:slug', loadChildren: () => import('./post/post.module').then(m => m.PostModule), data: {routeState: 3} },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
