import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './findOptions.component.html',
  styleUrls: ['./findOptions.component.css']
})
export class FindOptionsComponent implements OnInit {

  public sectors: Array<string>;
  public optionDates: Array<string>;
  public searchPressed = false;
  public lastModifiedTime = '';

  form = new FormGroup({
    optionDate: new FormControl('', [Validators.required]),
    optionType: new FormControl('Put', [Validators.required]),
    percentFromStockPrice: new FormControl('5', []),
    minAmountPerContract: new FormControl('.5', []),
    maxPriceOfStock: new FormControl('800', []),
    sector: new FormControl('', []),
    earnings: new FormControl('both', []),
    excludeZeroBids: new FormControl('true', [])
  });

  columnDefs = [
    {headerName: 'Symbol', field: 'Symbol', sortable: true, width: 100},
    {headerName: 'Type', field: 'Type', sortable: true, width: 100},
    {headerName: 'Our Rating(out of 5)', field: 'Rating', sortable: true, width: 100},
    {headerName: '% Out Of Money', field: 'PercentOutOfMoney', type: 'number', sortable: true, comparator: this.myComparator, width: 125},
    {headerName: 'Historical Hit Rate', field: 'HistoricalPercentageThatItWillHit', type: 'number', sortable: true, comparator: this.myComparator, width: 125},
    {headerName: 'Strike', field: 'Strike Price', type: 'number', valueFormatter: this.valueFormatter, sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Stock Price', field: 'Stock Price', type: 'number', sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Bid Price', field: 'Bid', type: 'number', sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Ask Price', field: 'Ask', type: 'number', sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Mid Price', field: 'Mid', type: 'number', sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Last Price', field: 'Last', type: 'number', sortable: true, comparator: this.myComparator, width: 100},
    {headerName: 'Earnings Within Record Date', field: 'IsOptionWithinRecord', sortable: true, width: 150},
    {headerName: 'Next Record Date', field: 'NextRecordDate', sortable: true, width: 125},
    {headerName: 'Sector', field: 'Sector', sortable: true, width: 150},
  ];

  rowData = [
  ];


  constructor(public httpClient: HttpClient) { }

  valueFormatter(params) {
    return '$' + params.value;
  }

  ngOnInit() {
    this.getSectors();
    this.getOptionDates();
    this.getLastModifiedTime();
  }

  getOptionDates() {
    this.httpClient.get<string[]>(environment.serverUrl + '/query/getOptionDates').subscribe(result => {
      this.optionDates = result;
      this.form.controls.optionDate.setValue(result[0]);
    });

  }

  getSectors() {
    this.httpClient.get<string[]>(environment.serverUrl + '/query/getSectors').subscribe(result => {
      this.sectors = result;
    });
  }

  getLastModifiedTime() {
    this.httpClient.get(environment.serverUrl + '/query/getLastModifiedTime', {responseType: 'text'}).subscribe(
      result => {
        this.lastModifiedTime = result;
      }
    )

    // this.httpClient.get<string>(environment.serverUrl + '/query/getLastModifiedTime').subscribe(result => {
    //   this.lastModifiedTime = result;
    // });
    // const options = {headers, params, responseType: 'text' as 'text'};
    // this.httpClient.get<string>(environment.serverUrl + '/query/getLastModifiedTime', {headers:
    //     new HttpHeaders({'content-type': 'application/json'}), responseType: 'text'}).subscribe(result => {
    //   this.lastModifiedTime = result;
    // });
  }

  optionFilterButtonClicked() {
      this.searchPressed = false;
      this.rowData = [];
  }

  lookForOptions() {
    let params = new HttpParams();
    params = params.append('optionDate', this.form.value.optionDate);
    params = params.append('minPercentFromStockPrice', this.form.value.percentFromStockPrice);
    params = params.append('minAmountPerContract', this.form.value.minAmountPerContract);
    params = params.append('maxPriceOfStock', this.form.value.maxPriceOfStock);
    params = params.append('optionType', this.form.value.optionType);
    params = params.append('sector', this.form.value.sector);
    params = params.append('earnings', this.form.value.earnings);
    params = params.append('excludeZeroBids', this.form.value.excludeZeroBids);
    // params = params.append('earnings', this.form.value.earnings);
    this.httpClient.get<Map<string, string>[]>(environment.serverUrl + '/query/lookForOptions', {params} ).subscribe(result => {
      this.rowData = result;
      this.searchPressed = true;
    });
  }

  myComparator(value1, value2) {
    if (value1 === null && value2 === null) {
      return 0;
    }
    if (value1 === null) {
      return -1;
    }
    if (value2 === null) {
      return 1;
    }
    return value1 - value2;
  }
}
