import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Subject, Observable } from 'rxjs';
import { IPost } from './post.model';
import { tap } from 'rxjs/operators';

const POSTS_URL = 'posts';


@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  posts: IPost[];
  post$: Subject<IPost> = new Subject();

  constructor(private http: HttpClient) {
  }

  getPosts(): Observable<IPost[]> {
    if (this.posts) {
      return of(this.posts);
    }

    //${POSTS_URL}
    return this.http.get<IPost[]>('https://167.172.157.37:70/wp-json/wp/v2/posts').pipe(
      tap((posts) => this.posts = posts)
    );
  }

  getPost(id: number) {
    if (this.posts) {
      const post = this.posts.find(p => p.id === id);
      if (post) {
        this.post$.next(post);
      }
    }

    //${POSTS_URL}/${id}
    this.http.get<IPost>(`https://167.172.157.37:70/wp-json/wp/v2/posts/${id}`).subscribe(post => this.post$.next(post));
  }
}
