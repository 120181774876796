import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {WordpressService} from '../wordpress.service';
import {IPost} from '../post.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  posts$ : Observable<IPost[]>

  constructor(private wordpressService: WordpressService) {
  }

  ngOnInit(): void {
    this.posts$ = this.wordpressService.getPosts();
  }

}
